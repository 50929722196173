import { useState, useEffect } from "react"
import { createContainer } from "unstated-next"
import FirebaseState from "./FirebaseState"
import CurrentUserState from "./CurrentUserState"

const useMessagingState = () => {
  const { messaging } = FirebaseState.useContainer()
  const { setCurrentFCMToken } = CurrentUserState.useContainer()

  useEffect(() => {
    if (!messaging) return
    init()
  }, [messaging])

  const init = async () => {
    messaging.getToken().then(async (currentToken) => {
      if (currentToken) {
        setCurrentFCMToken(currentToken)
      } else {
        console.log('No Instance ID token available. Request permission to generate one.');
        // Show permission UI.
        // updateUIForPushPermissionRequired();
        // setTokenSentToServer(false);
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // showToken('Error retrieving Instance ID token. ', err);
      // setTokenSentToServer(false);
    });
    messaging.onTokenRefresh(() => {
      messaging.getToken().then((refreshedToken) => {
        console.log('Token refreshed.');
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        // setTokenSentToServer(false);
        // Send Instance ID token to app server.
        // sendTokenToServer(refreshedToken);
        // ...
      }).catch((err) => {
        console.log('Unable to retrieve refreshed token ', err);
        // showToken('Unable to retrieve refreshed token ', err);
      });
    });

    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
    })
    
  }
  return {
    messaging
  }
}

export const MessagingState = createContainer(useMessagingState)

export default MessagingState
