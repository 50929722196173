import { useState, useEffect } from "react"
import { createContainer } from "unstated-next"
import { navigate } from "gatsby"
import FirebaseState from "./FirebaseState"

const useAuthentication = () => {
  const { firebase } = FirebaseState.useContainer() 
  const [isAuthenticated, setIsAuthenticated] = useState()

  useEffect(() => {
    if (!firebase) return
    firebase.auth().onAuthStateChanged(
      user => {
        if (user) {
          setIsAuthenticated(true)
        } else {
          setIsAuthenticated(false)
        }
      }
    )
  }, [firebase])

  const logout = () => {
    firebase.auth().signOut()
  }

  return { logout, isAuthenticated: !(isAuthenticated === false) }
}

export const AuthenticationState = createContainer(useAuthentication)

export default AuthenticationState
