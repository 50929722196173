import React, { createElement } from "react"
import FirebaseState from "./src/state/FirebaseState"
import AuthenticationState from "./src/state/AuthenticationState"
import CurrentUserState from "./src/state/CurrentUserState"
import GlobalStyles from "./src/components/global.styles"
import { IntlProvider } from "react-intl"
import MessagingState from "./src/state/MessagingState"

export const wrapRootElement = ({ element }) => (
  <>
    <GlobalStyles />
    <IntlProvider locale="fr-FR">
      <FirebaseState.Provider>
        <AuthenticationState.Provider>
          <CurrentUserState.Provider>
            <MessagingState.Provider>{element}</MessagingState.Provider>
          </CurrentUserState.Provider>
        </AuthenticationState.Provider>
      </FirebaseState.Provider>
    </IntlProvider>
  </>
)

export const onServiceWorkerUpdateReady = () => {
  const element = document.createElement("div")
  element.className = 'new-version-widget'
  element.innerText = '💌 Nouvelle version!'
  element.onclick = () => window.location.reload()
  document.body.appendChild(element)
}
