import { useState, useEffect } from "react"
import { createContainer } from "unstated-next"
import FirebaseState from "./FirebaseState"

const useCurrentUser = () => {
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState({})
  const { firebase, db } = FirebaseState.useContainer()
  const [fcmToken, setFCMToken] = useState({ token: null, sentToServer: false })

  useEffect(() => {
    if (!currentUser.uid || !currentUser.name) return
    if (!fcmToken.token || fcmToken.sentToServer) return
    addFCMToken()
  }, [fcmToken, currentUser])

  const onUser = async (user) => {
    setCurrentUser(user)
    await new Promise((resolve) => {
      firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .get().then((doc) => {
        setCurrentUser({ ...user, permissions: {}, ...doc.data() })
        resolve()
      })
    })
  }

  const setCurrentFCMToken = (token) => {
    setFCMToken({ ...fcmToken, token: token })
  }

  const addFCMToken = async () => {
    if (fcmToken.sentToServer) return
    if ((currentUser.fcmTokens || []).indexOf(fcmToken.token) < 0) {
      await db.collection('users').doc(currentUser.uid).update({
        fcmTokens: [...(currentUser.fcmTokens || []), fcmToken.token]
      })
      setFCMToken({ ...fcmToken, sentToServer: true })
    }
  }

  useEffect(() => {
    if (!firebase) return
    setLoading(true)
    firebase.auth().onAuthStateChanged(
      async user => {
        if (user) {
          await onUser(user)
        } else {
          setCurrentUser({})
        }
        setLoading(false)
      }
    )
  }, [firebase])

  return { loading, currentUser, setCurrentUser, setCurrentFCMToken }
}

export const CurrentUserState = createContainer(useCurrentUser)

export default CurrentUserState