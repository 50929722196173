import { useState, useEffect } from "react"
import { createContainer } from "unstated-next"

const config = {
  apiKey: "AIzaSyDuW8rUC8tLPXzZD5NNisWHXDVESVYNUMM",
  authDomain: "amour.benafonso.com",
  databaseURL: "https://benexperiments.firebaseio.com/",
  projectId: "benexperiments",
  storageBucket: "gs://benexperiments.appspot.com",
  messagingSenderId: "905239180337",
  appId: "1:905239180337:web:14d3c7031d00244a355110",
  measurementId: "G-CH7ZJZ1VJX",
}

const useFirebaseState = () => {
  const [firebase, setFirebase] = useState()

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    const lazyFirebase = import("firebase/app")
    const lazyFirestore = import("firebase/firestore")
    const lazyStorage = import("firebase/storage")
    const lazyAuth = import("firebase/auth")
    const lazyDatabase = import("firebase/database")
    const lazyAnalytics = import("firebase/analytics")
    const lazyMessaging = import("firebase/messaging")

    const [firebase] = await Promise.all([
      lazyFirebase,
      lazyFirestore,
      lazyAuth,
      lazyStorage,
      lazyAnalytics,
      lazyMessaging,
    ])
    firebase.initializeApp(config)
    setFirebase(firebase)
  }

  const db = firebase && firebase.firestore()
  const storage = firebase && firebase.storage()
  const auth = firebase && firebase.auth()
  const analytics = firebase && firebase.analytics()
  return {
    firebase,
    db,
    storage,
    auth,
    analytics,
    messaging: firebase && firebase.messaging.isSupported() && firebase.messaging(),
  }
}

export const FirebaseState = createContainer(useFirebaseState)

export default FirebaseState
