// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-release-note-index-js": () => import("../src/templates/release-note/index.js" /* webpackChunkName: "component---src-templates-release-note-index-js" */),
  "component---src-pages-album-js": () => import("../src/pages/album.js" /* webpackChunkName: "component---src-pages-album-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-js": () => import("../src/pages/install.js" /* webpackChunkName: "component---src-pages-install-js" */),
  "component---src-pages-koh-lanta-js": () => import("../src/pages/koh-lanta.js" /* webpackChunkName: "component---src-pages-koh-lanta-js" */),
  "component---src-pages-message-du-jour-create-js": () => import("../src/pages/message-du-jour/create.js" /* webpackChunkName: "component---src-pages-message-du-jour-create-js" */),
  "component---src-pages-message-du-jour-history-js": () => import("../src/pages/message-du-jour/history.js" /* webpackChunkName: "component---src-pages-message-du-jour-history-js" */),
  "component---src-pages-message-du-jour-index-js": () => import("../src/pages/message-du-jour/index.js" /* webpackChunkName: "component---src-pages-message-du-jour-index-js" */),
  "component---src-pages-message-js": () => import("../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-messages-js": () => import("../src/pages/messages.js" /* webpackChunkName: "component---src-pages-messages-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-todo-js": () => import("../src/pages/todo.js" /* webpackChunkName: "component---src-pages-todo-js" */),
  "component---src-pages-videos-js": () => import("../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-watch-js": () => import("../src/pages/watch.js" /* webpackChunkName: "component---src-pages-watch-js" */)
}

